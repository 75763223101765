import { Component, createRef } from "react";
import LeftPanel from "./left";
import RightPanel from "./right";
import {generateHTMLView, generateChildrenViews, pushTreeBranch, removeBranch, findBranch, duplicateBranch, findEditable} from "../functions/html"
import { apiUrl } from "../config/var";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Editor from "./editor";
import FloatingRight from "./floatingRight";
import elementStyles from "../config/styleFields.json"
import PreviewFrom from "./previewForm";

export default class Main extends Component {
    counter = 0

    constructor(props){
        super(props)

        this.state = {
            title : '',
            content : '',
            message : null,
            savedProjects : [],
            currentProject : '',
            currentSaved : true,
            page: {
                elements: {},
                tree: [],
                files: []
            },
            active: null,
            blockUi : false,
            previewWindow : null,
            rightClickTarget : false
        }
    }

    componentDidMount(){
        this.getSavedProjects()
    }

    getSavedProjects(){
        this.blockUi(true)

        fetch(apiUrl + 'index.php?action=projects')
        .then( response => response.json())
        .then( json => {
            this.setState(s => {
                s.blockUi = false
                s.savedProjects = json.data
                return s
            })
        })
    }

    fixedDropClick(id){
        this.setState(s => {
            s.active = null
            s.rightClickTarget = id

            return s
        })
    }

    floatingRightClose(){
        this.setState(s => {
            s.rightClickTarget = false

            return s
        })
    }

    floatingRightItemClick(el, targetId){
        this.addElement(el, targetId)

        this.floatingRightClose()
    }

    blockUi(block) {
        this.setState(s => {
            s.blockUi = block
            return s
        })
    }

    showBlockUi() {
        let blockDisplay = 'none';

        if (this.state.blockUi == true) {
            blockDisplay = 'block';
        }

        return (
            <div className="blockui" style={{ display: blockDisplay }}></div>
        )
    }

    onTitleUpdate(title) {
        this.setState(s => {
            s.title = title
            return s
        })
    }

    elementClick(e, el) {
        e.stopPropagation()
        e.preventDefault()

        if( el == null ) return

        this.setActive(el)
    }

    setActive(el){
        
        this.setState(s => {
            s.active = null
            return s
        }, () => {
            this.setState(s => {
                s.active = {...el}
                return s
            })
        })
    }

    onElementUpdate(page){
        this.setState(s => {
            s.page = {...page}
            
            return s
        })
    }

    onEditorUpdate(key, val, isStyle, file){
        isStyle = isStyle || false
        
        let element = {...this.state.page.elements[this.state.active.id]}

        // let element = this.state.page.elements[this.state.active.id]

        if (isStyle) {
            let style = { ...element.style}
            style[key] = val
            element.style = style
        } else {
            let data = {...element.data}
            data[key] = val
            element.data = data
        }

        // console.log(element)
        // console.log(this.state.active.id)
        this.setState(s => {
            s.page.elements[s.active.id] = element

            s.active = element

            if( file !== null){
                s.page.files.push(file)
            }

            return s
        })
    }

    deleteImage(key, url, isStyle) {
        isStyle = isStyle || false

        let element = this.state.page.elements[this.state.active.id]
        
        if (isStyle) {
            delete element.style[key]
        } else {
            element.data[key] = 'icons/image.png'
        }

        let files = this.state.page.files

        files.splice(files.indexOf(url), 1)

        this.setState(s => {
            s.page.elements[s.active.id] = { ...element }
            s.page.files = files
            
            return s
        })
    }

    generateHTML(preview){
        let html = ''

        if( this.state.page == null ) return html
        
        this.state.page.tree.forEach(branch => {
            let id = branch.id
            
            if( typeof this.state.page.elements[id] != 'undefined' ) {
                let branchView = generateHTMLView(this.state.page.elements[id], preview, apiUrl)

                if(branchView !== false){
                    html += branchView.outer + branchView.open

                    if (branch.children.length > 0) {
                        html += this.generateHTMLChildren(branch.children, preview)
                    }

                    html += branchView.close + branchView.outerClose
                }

            }

        })

        return html
    }

    generateHTMLChildren(children, preview) {
        let html = ''

        children.forEach(child => {
            let id = child.id

            if (typeof this.state.page.elements[id] != 'undefined') {
                let childView = generateHTMLView(this.state.page.elements[id], preview, apiUrl)
                
                if( childView !== false ){
                    html += childView.outer + childView.open

                    if (child.children.length > 0) {
                        html += this.generateHTMLChildren(child.children, preview)
                    }

                    html += childView.close + childView.outerClose
                }
            }
        })

        return html
    }

    saveProject( showMessage ){
        showMessage = showMessage || true

        this.blockUi(true)

        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('counter', this.counter)
        formData.append('tree', JSON.stringify(this.state.page.tree))
        formData.append('elements', JSON.stringify(this.state.page.elements))
        formData.append('files', JSON.stringify(this.state.page.files))

        fetch(apiUrl + 'index.php?action=saveproject', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            referrerPolicy: 'no-referrer',
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                let message = {
                    status : json.status,
                    message : json.message
                }

                let savedProjects = this.state.savedProjects
                let currentProject = this.state.currentProject
                let currentSaved = false

                if( json.status == 'success' ){
                    savedProjects = json.savedProjects
                    currentProject = json.data.key
                    currentSaved = true
                }

                this.setState(s => {
                    if(showMessage == true){
                        s.message = message
                    }
                    
                    s.savedProjects = savedProjects
                    s.currentProject = currentProject
                    s.currentSaved = currentSaved
                    s.blockUi = false
                    return s
                })
            })
    }

    publishProject(){
        this.saveProject(false)

        this.blockUi(true)


        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('body', this.generateHTML())
        formData.append('images', JSON.stringify(this.state.page.files))
        formData.append('overwrite', 'no')

        fetch(apiUrl + 'index.php?action=publish', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            referrerPolicy: 'no-referrer',
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                let message = {
                    status: json.status,
                    message: json.message
                }

                if( json.key ){
                    message.view = apiUrl.replace('api/', json.key + '/index.html')
                }

                this.setState(s => {
                    s.message = message
                    s.blockUi = false
                    return s
                }, () => {
                    if (json.status == 'error' && json.err_code && json.err_code == 'overwrite'){
                        if( window.confirm('Proceed to overwrite existing project?') == false ) return

                        formData.set('overwrite', 'yes')
                        
                        fetch(apiUrl + 'index.php?action=publish', {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'no-cache',
                            credentials: 'omit',
                            referrerPolicy: 'no-referrer',
                            body: formData
                        })
                            .then(response => response.json())
                            .then(json => {
                                let message = {
                                    status: json.status,
                                    message: json.message
                                }

                                if (json.key) {
                                    message.view = apiUrl.replace('api/', json.key + '/index.html')
                                }

                                this.setState(s => {
                                    s.message = message
                                    s.blockUi = false
                                    return s
                                })
                            })
                    }
                })
            })
    }

    loadProject(e){
        let targetProject = e.target.value

        if( targetProject.length == 0 || targetProject == this.state.currentProject) return

        if( this.state.currentSaved === false ){
            if (window.confirm('Unsaved changes will be lost. Are you sure?') === false) return
        }
        
        this.blockUi(true)

        fetch(apiUrl + 'index.php?action=loadproject&key=' + targetProject)
            .then(response => response.json())
            .then(json => {
                let elements = {}
                let tree = []
                let currentProject = ''
                let currentSaved = false
                let title = ''
                let files = []

                let message = {
                    status: json.status,
                    message: json.message
                }

                if( json.status == 'success' ){
                    if( json.data.tree.length == 0 || json.data.elements == null){
                        message.status = 'error'
                        message.message = 'This project is empty'
                    }else{
                        this.counter = json.data.counter
                        elements = json.data.elements
                        tree = json.data.tree
                        currentProject = json.data.key
                        currentSaved = true
                        title = json.data.name
                        files = json.data.files
                    }
                }
                this.setState(s => {
                    s.message = message
                    s.page.tree = tree
                    s.page.elements = elements
                    s.page.files = files
                    s.active = null
                    s.rightClickTarget = false
                    s.currentProject = currentProject
                    s.currentSaved = currentSaved
                    s.title = title
                    s.blockUi = false
                    return s
                })
            })
    }

    onPreviewFormSubmit(e){
        let content = this.generateHTML(true)

        this.setState(s => {
            s.content = content
            return s
        }, () => {
            window.setTimeout(() => {
                document.getElementById('preview-form').submit()
            }, 250)
        })
    }

    onPreviewClick(e){
        let previewUrl = apiUrl + 'preview.php'
        let postData = {
            title : this.state.title,
            body: this.generateHTML(true)
        }

        let previewWindow = this.state.previewWindow

        if (previewWindow == null || previewWindow.closed == true) {
            let message = {}

            previewWindow = window.open(previewUrl)

            if(previewWindow == null){
                message.status = 'error'
                message.message = 'Could not open window'
            }else{
                setTimeout(() => {
                    previewWindow.postMessage(JSON.stringify(postData), previewUrl)
                }, 1500)
            }

            this.setState(s => {
                s.previewWindow = previewWindow
                s.message = message
                return s
            })
        }else{
            previewWindow.postMessage(JSON.stringify(postData), previewUrl)
            previewWindow.focus()
        }
    }
    
    onDownloadClick(){
        this.setState(s => {
            s.message = null
            s.blockUi = true
            return s
        })

        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('body', this.generateHTML())
        formData.append('images', JSON.stringify(this.state.page.files))

        fetch(apiUrl + 'index.php?action=download', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            referrerPolicy: 'no-referrer',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Content-Length': JSON.stringify(postData).length
                // 'Accept': '*/*'
            },
            // body: JSON.stringify(postData)
            body : formData
        })
        .then( response => response.json())
        .then( json => {
            this.setState( s => {
                s.message = json
                s.blockUi = false
                return s
            })
        })
    }

    handleDrop(e, id) {

        e.preventDefault()
        e.stopPropagation()
        // e.nativeEvent.stopImmediatePropagation()

        let target = e.target
        let shouldDrop = target.getAttribute('data-drop')

        if (shouldDrop == null || shouldDrop === 'false') {
            console.log('no drop')
            let parent = target.closest('[data-drop="true"]')

            if (parent == null) {
                id = null
            } else {
                id = parent.getAttribute('data-id')
            }
        }

        let data = e.dataTransfer.getData('text')
        // e.dataTransfer.clearData()

        if (data == null) return

        let droppedEl = JSON.parse(data)

        this.addElement(droppedEl, id)
    }

    copyElement(e) {
        e.stopPropagation()
        e.preventDefault()

        if (this.state.active !== null) {
            let branch = findBranch(this.state.active.id, this.state.page.tree)

            if (branch !== false) {
                let duplicate = duplicateBranch(branch, this.state.page.elements, this.state.page.tree, this.counter)

                this.counter = duplicate.counter
                let elements = this.state.page.elements
                elements[duplicate.counter] = duplicate.copy

                this.setState(s => {
                    s.page.elements = elements
                    s.page.tree = duplicate.tree
                    s.currentSaved = false
                    s.active = null
            
                    return s
                })
            }
        }

    }

    removeElement(e) {

        if (this.state.active !== null) {
            this.setState(s => {
                delete s.page.elements[this.state.active.id]

                s.page.tree = removeBranch(this.state.active.id, s.page.tree)

                return s
            }, () => {
                this.closeEditor(e)
            })
        }
    }

    async closeEditor(e){
        let r = this.setState(s => {
            s.active = null
            return s
        })

        return Promise.resolve(r)
    }

    addElement(droppedEl, id) {
        let pageEl = null
        let elId = 0
        let pageElements = this.state.page.elements

        let tree = this.state.page.tree

        if (typeof droppedEl.id != 'undefined') {
            pageEl = droppedEl
            elId = droppedEl.id
            tree = removeBranch(elId, tree)
        } else {
            this.counter++

            elId = this.counter

            pageEl = {
                id: elId,
                name: droppedEl.name,
                type: droppedEl.type,
                drop: droppedEl.drop || false,
                drag: false,
                edit : droppedEl.edit || false,
                tag: droppedEl.tag,
                classList: '',
                fields: droppedEl.fields,
                data: {
                    text: ''
                },
                style: {}
            }

            if (typeof droppedEl.classList != 'undefined') {
                pageEl.classList = droppedEl.classList
            }

            if (typeof elementStyles[droppedEl.type] != 'undefined'){
                for (let elementStyle of elementStyles[droppedEl.type] ){
                    if( elementStyle.key == 'class' ){
                        pageEl.classList += elementStyle.value.val + ' '
                    }else{
                        pageEl.style[elementStyle.key] = elementStyle.value
                    }
                }
            }

            if (typeof droppedEl.drag != 'undefined' && droppedEl.drag == true) {
                pageEl.drag = true
            }
        }

        if (droppedEl.fields.length > 0) {
            droppedEl.fields.forEach(field => {
                if (field.key == 'text') {
                    pageEl.data.text = field.default
                }

                if (field.key == 'src') {
                    pageEl.data.src = field.default
                }
            })
        }

        pageElements[elId] = pageEl


        let branch = {
            id: elId,
            children: []
        }

        if (typeof droppedEl.children != 'undefined' && droppedEl.children.length > 0) {
            let childrenView = generateChildrenViews(droppedEl.children, pageElements, tree, this.counter)
            branch.children = childrenView.branches
            pageElements = childrenView.pageElements
            tree = childrenView.tree
            this.counter = childrenView.counter
        }

        if (id !== null) {
            tree = pushTreeBranch(branch, id, tree)
        } else {
            tree.push(branch)
        }

        let editable = findEditable(branch, pageElements)

        this.setState(s => {
            s.page.elements = pageElements
            s.page.tree = tree
            s.active = editable
            s.currentSaved = false
            return s
        })

    }

    branchMove(i, up) {
        let tree = this.state.page.tree
        let nTree = []
        let branch = tree.splice(i, 1)

        let target = 0

        if (up == true) {
            target = i - 1
            if (target < 0) {
                target = 0
            }
        } else {
            target = i + 1
        }

        for (let n = 0; n < tree.length; n++) {
            if (n === target) {
                nTree.push(branch[0])
                branch = null
            }
            nTree.push(tree[n])
        }

        if (branch !== null) {
            nTree.push(branch[0])
        }

        this.setState(s => {
            s.page.tree = nTree
            return s
        })
    }


    dismissMessage(){
        this.setState(s => {
            s.message = null
            return s
        })
    }

    displayMessage(){
        if (this.state.message == null || typeof this.state.message.message == 'undefined') return false

        let donwload = false

        if (typeof this.state.message.zip != 'undefined' ){
            donwload = <a href={apiUrl + this.state.message.zip} target="_blank" download={true}>Download</a>
        }

        if (typeof this.state.message.url != 'undefined') {
            donwload = <a href={apiUrl + this.state.message.url + '?v=' + Date.now()} target="_blank">Preview</a>
        }

        if (typeof this.state.message.directLink != 'undefined') {
            donwload = <a href={apiUrl + this.state.message.directLink} target="_blank" download={true}>Download</a>
        }

        if (typeof this.state.message.view != 'undefined') {
            donwload = <a href={this.state.message.view} target="_blank">View Project</a>
        }

        return(
            <div className={'message ' + this.state.message.status}>
                <span>{this.state.message.message}</span>
                <span>
                    {donwload}
                    <button type="button" className="editor-btn btn-sm btn-delete" onClick={e => this.dismissMessage()}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </span>
            </div>
        )
    }

    render() {
        let right = false

        if( this.state.active !== null ){
            right = <Editor active={this.state.active} tree={this.state.page.tree} onBlockUi={this.blockUi.bind(this)} onUpdate={this.onEditorUpdate.bind(this)} onCopy={this.copyElement.bind(this)} onRemove={this.removeElement.bind(this)} branchMove={this.branchMove.bind(this)} deleteImage={this.deleteImage.bind(this)} onClose={this.closeEditor.bind(this)}/>
        }else{
            right = <RightPanel savedProjects={this.state.savedProjects} currentProject={this.state.currentProject} onProjectChange={this.loadProject.bind(this)} onSaveClick={this.saveProject.bind(this)} onPublishClick={this.publishProject.bind(this)} title={this.state.title} onTitleUpdate={this.onTitleUpdate.bind(this)} onBlockUi={this.blockUi.bind(this)} onDownloadClick={this.onDownloadClick.bind(this)} onPreviewClick={this.onPreviewFormSubmit.bind(this)} />
        }
        
        let floatingRight = false

        if( this.state.rightClickTarget !== false ){
            floatingRight = <FloatingRight targetId={this.state.rightClickTarget} onItemClick={this.floatingRightItemClick.bind(this)} onCloseClick={this.floatingRightClose.bind(this)} />
        }

        return (
            <div className="main-outer">
                {this.showBlockUi()}
                <div className="main">
                    {floatingRight}
                    <div className="panel-left">
                        {this.displayMessage()}
                        <LeftPanel page={this.state.page} active={this.state.active} apiUrl={apiUrl} onBlockUi={this.blockUi.bind(this)} onDrop={this.handleDrop.bind(this)} onElementClick={this.elementClick.bind(this)} onFixedDropClick={this.fixedDropClick.bind(this)}/>
                    </div>
                    {right}
                    <PreviewFrom title={this.state.title} content={this.state.content} />
                </div>
            </div>
            
        )
    }
}