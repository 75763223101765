import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import elements from "../config/elements.json"

export default class FloatingRight extends Component{
    constructor(props){
        super(props)
    }

    render(){
        let elementsView = []

        for (let t in elements) {
            let el = elements[t]

            let icon = false

            if (typeof el.eicon !== 'undefined' && el.eicon.length > 0) {
                icon = (
                    <i className={el.eicon}></i>
                )
            } else if (typeof el.icon !== 'undefined' && el.icon.length > 0) {
                icon = (
                    <img src={el.icon} alt={el.name} title={el.name}/>
                )
            } else {

                icon = (
                    <img src="icons/html.png" alt={el.name} />
                )
            }

            elementsView.push(
                <div className="draggable not-draggable" draggable={false} onClick={e => this.props.onItemClick(el, this.props.targetId)} key={t}>
                    <span>
                        {icon}
                    </span>
                </div>
            )
        }

        return(
            <div className="panel-right">
                <div className="message info">
                    <span>Click on elements to add to selected area</span>
                    <span>
                        <button type="button" className="editor-btn btn-delete" onClick={e => this.props.onCloseClick()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </span>
                </div>
                <div className="elements-wrapper">
                    {elementsView}
                </div>
            </div>
        )
    }
}