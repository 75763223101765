import { faCloudArrowUp, faDownload, faEye, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import elements from "../config/elements.json"
import Draggable from "./draggable";

export default class RightPanel extends Component {
    constructor(props){
        super(props)

        this.state = {
            elements : elements,
            title : '',
            titleError : ''
        }
    }

    titleInput(e){
        let target = e.target

        this.props.onTitleUpdate(target.value)

        let titleError = ''

        if( target.value.length < 1){
            titleError = 'Please provide an Unique Title'
        }

        this.setState(s => {
            s.titleError = titleError
            return s
        })
    }

    titleError(){
        if( this.state.titleError.length < 1 ) return false

        return(
            <div className="message error">
                {this.state.titleError}
            </div>
        )
    }

    hasTitle(){
        let titleError = ''

        if (this.props.title.length < 1) {
            titleError = 'Please provide a title'
        }

        this.setState(s => {
            s.titleError = titleError
            return s
        })

        return this.props.title.length > 0
    }

    onDownloadClick(e){
        if(this.hasTitle() == true){
            this.props.onDownloadClick()
        }
    }

    onPublishClick(e){
        if (this.hasTitle() == true) {
            this.props.onPublishClick()
        }
    }

    onSaveClick(e) {
        if (this.hasTitle() == true) {
            this.props.onSaveClick()
        }
    }

    onPreviewClick(e) {
        this.props.onPreviewClick(e)
    }

    elementsView(){
        let views = []

        for (let t in this.state.elements) {
            let el = this.state.elements[t]

            views.push(
                <Draggable key={t} element={el} />
            )
        }
        
        return views
    }

    render() {
        let title = (<div className="message">Drag elements and drop on preview area</div>)
        let savedOptions = []
        
        savedOptions.push(
            <option value="" key="blank">Select One</option>
        )

        this.props.savedProjects.forEach(saved => {
            savedOptions.push(
                <option value={saved.key} key={saved.key}>{saved.name}</option>
            )
        })

        return (
            <div className="panel-right" onDropCapture={e => e.nativeEvent.stopPropagation()}>
                {title}        
                <div className="elements-wrapper">
                    {this.elementsView()}
                </div>
                <div className="search-wrapper editor-row">
                    <div className="editor-cell">
                        <label>Load Project</label>
                    </div>
                    <div className="editor-cell">
                        <select className="input" onChange={this.props.onProjectChange} value={this.props.currentProject}>
                            {savedOptions}
                        </select>
                    </div>
                </div>
                <div className="search-wrapper">
                    <label>Download</label>
                    <input type="text" className="input" placeholder="Project Title" value={this.props.title} onInput={e => this.titleInput(e)} onPaste={e => this.titleInput(e)}/>
                    {this.titleError()}
                    <div className="editor-btns">
                        <button type="button" className="editor-btn btn-block btn-primary" onClick={e => this.onPreviewClick(e)} title="Preview">
                            <FontAwesomeIcon icon={faEye} />&nbsp;
                            <span className="hide-sm">Preview</span>
                        </button>
                        <button type="button" className="editor-btn btn-block btn-primary" onClick={e => this.onDownloadClick(e)} title="Download">
                            <FontAwesomeIcon icon={faDownload} />&nbsp;
                            <span className="hide-sm">Download</span>
                        </button>
                        <button type="button" className="editor-btn btn-block btn-primary" onClick={e => this.onSaveClick(e)} title="Save">
                            <FontAwesomeIcon icon={faFloppyDisk} />&nbsp;
                            <span className="hide-sm">Save</span>
                        </button>
                        <button type="button" className="editor-btn btn-block btn-primary" onClick={e => this.onPublishClick(e)} title="Publish">
                            <FontAwesomeIcon icon={faCloudArrowUp} />&nbsp;
                            <span className="hide-sm">Publish</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}