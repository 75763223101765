import { Component } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Editor } from "@tinymce/tinymce-react";

export default class RichTextarea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value
        }
    }

    handleChange(val){
        this.setState(s => {
            s.value = val
            return s
        }, () => {
            this.props.onChange(this.props.inputKey, this.state.value, this.props.isStyle)
        })
    }

    render() {
        
        return(
            <div className="rich-textarea-wrapper">
                <Editor value={this.state.value} tinymceScriptSrc="tinymce/tinymce.min.js" onEditorChange={this.handleChange.bind(this)} init={{
                    height: 500,
                    menubar: false,
                    // plugins: [
                    //     'advlist autolink lists link image charmap print preview anchor',
                    //     'searchreplace visualblocks code fullscreen',
                    //     'insertdatetime media table paste code help wordcount'
                    // ],
                    toolbar1: 'undo redo | bold italic forecolor backcolor | fontsize fontfamily | removeformat',
                    toolbar2: 'formatselect lineheight |  alignleft aligncenter alignright | bullist numlist outdent indent',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    branding: false,
                }}
                />
                {/* <ReactQuill onChange={this.handleChange.bind(this)} value={this.state.value} /> */}
            </div>
        )
    }
}