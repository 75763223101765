import { Component } from "react";
import { apiUrl } from "../config/var";

export default class PreviewFrom extends Component{
    render(){
        return(
            <form id="preview-form" className="preview-form" method="POST" action={apiUrl + 'preview.php'} target="_blank" encType="multipart/form-data">
                <input readOnly type="hidden" name="title" value={this.props.title} />
                <textarea readOnly style={{ display: 'none' }} name="content" value={ this.props.content } />
            </form>
        )
    }
}