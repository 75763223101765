import elementStyles from "../config/styleFields.json"

export function generateHTMLView( element, preview, apiUrl ){
    if( preview == null ){
        preview = false
    }

    // if( typeof elements[element.type] == 'undefined' ) return false

    // let el = elements[element.type]

    let open = '<' + element.tag
    let outer = ''
    let outerClose = ''

    if (typeof element.data.link != 'undefined' && element.data.link !== null) {
        if (element.type == 'img') {
            outer = '<a href="' + element.data.link + '">'
            outerClose = '</a>'
        }else{
            open += ' href="' + element.data.link + '"'
        }
    }

    if (typeof element.data.src != 'undefined' && element.data.src !== null) {
        if( preview == true ){
            open += ' src="' + apiUrl + element.data.src + '"'
        }else{
            open += ' src="' + element.data.src + '"'
        }
        
    }

    let classList = ' class="' + camelToKebab( element.type ) + ' '

    if (typeof element.classList != 'undefined' && element.classList !== null && element.classList.length > 0) {
        classList += element.classList 
    }

    if (typeof element.attrId != 'undefined' && element.attrId !== null) {
        open += ' id="' + element.attrId + '"'
    }

    if (typeof element.alt != 'undefined' && element.alt !== null) {
        open += ' alt="' + element.alt + '"'
    }

    let style = '';

    if (typeof element.style != 'undefined' && element.style !== null) {
        

        for(let k in element.style){
            if( k.toString() == 'backgroundImage' ){
                style += camelToKebab(k.toString()) + ':url('
                if (preview == true) {
                    style += apiUrl + element.style[k].val
                } else {
                    style += element.style[k].val
                }
                
                style += ');'
            } else if (k.toString() == 'class') {
                classList += ' ' + element.style[k].val
            }else{
                style += camelToKebab(k.toString()) + ':' + element.style[k].val + element.style[k].suffix + ';'
            }
            
        }

    }

    open += classList + '" '

    if (style.length > 0) {
        open += ' style="' + style + '"'
    }

    let close = '</' + element.tag + '>'

    const selfClosing = ['img']

    if( selfClosing.indexOf(element.type) >= 0 ){
        close = '/>'
    }else{
        open += '>'
    }

    if (typeof element.data.text != 'undefined' && element.data.text !== null) {
        open += element.data.text
    }

    return {
        open : open,
        close : close,
        outer : outer,
        outerClose : outerClose
    }
}

export function generateChildrenViews(children, pageElements, tree, counter) {
    let branches = []
    children.forEach(droppedChild => {
        counter++

        let chId = counter

        let pageEl = {
            id: chId,
            name: droppedChild.name,
            type: droppedChild.type,
            drop: droppedChild.drop || false,
            edit: droppedChild.edit || false,
            drag: false,
            tag: droppedChild.tag,
            classList: '',
            fields: droppedChild.fields,
            data: {
                text: ''
            },
            style: {}
        }

        if (typeof droppedChild.classList != 'undefined') {
            pageEl.classList = droppedChild.classList
        }
        
        if (typeof elementStyles[droppedChild.type] != 'undefined') {
            for (let elementStyle of elementStyles[droppedChild.type]) {
                if (elementStyle.key == 'class') {
                    pageEl.classList += ' ' + elementStyle.value.val
                } else {
                    pageEl.style[elementStyle.key] = elementStyle.value
                }
            }
        }

        if (typeof droppedChild.drag != 'undefined' && droppedChild.drag == true) {
            pageEl.drag = true
        }

        if (droppedChild.fields.length > 0) {
            droppedChild.fields.forEach(field => {
                if (field.key == 'text') {
                    pageEl.data.text = field.default
                }

                if (field.key == 'src') {
                    pageEl.data.src = field.default
                }
            })
        }

        pageElements[chId] = pageEl

        let childBranch = {
            id: chId,
            children: []
        }

        if (typeof droppedChild.children != 'undefined' && droppedChild.children.length > 0) {
            let grandChildren = generateChildrenViews(droppedChild.children, pageElements, tree, counter)
            childBranch.children = grandChildren.branches
            pageElements = grandChildren.pageElements
            tree = grandChildren.tree
            counter = grandChildren.counter
        }

        branches.push(childBranch)
    })

    return {
        branches: branches,
        pageElements: pageElements,
        tree: tree,
        counter: counter
    }
}

export function removeBranch(id, tree){
    
    for( let i in tree ){
        if(tree[i].id == id){
            tree.splice(i, 1)
        }else{
            if (typeof tree[i].children != 'undefined' && tree[i].children.length > 0 ){
                tree[i].children = removeBranch(id, tree[i].children)
            }
        }
    }

    return tree
}

export function findBranch(id, tree){
    let branch = false

    for (let i in tree) {
        if (tree[i].id == id) {
            branch = tree[i]
            break
        }else{
            if (typeof tree[i].children != 'undefined' && tree[i].children.length > 0) {
                branch = findBranch(id, tree[i].children)
            }
        }
    }

    return branch
}

export function duplicateBranch(originalBranch, elements, tree, counter){
    counter++
    
    let copy = {...elements[originalBranch.id]}
    copy.id = counter

    let branch = {
        id : counter,
        children : []
    }

    if (typeof originalBranch.children != 'undefined' && originalBranch.children.length > 0) {
        for (let i in originalBranch.children){
            // console.log(originalBranch.children[i])
            let duplicateChild = duplicateBranch(originalBranch.children[i], elements, branch.children, counter)

            elements[duplicateChild.counter] = duplicateChild.copy
            counter = duplicateChild.counter
            branch.children = duplicateChild.tree
        }
    }

    tree.push(branch)

    return{
        copy : copy,
        elements : elements,
        tree : tree,
        counter : counter
    }
}

export function pushTreeBranch(branch, id, tree) {
    tree.forEach(b => {

        if (b.id == id) {
            b.children.push(branch)
        } else {
            if (b.children.length > 0) {
                b.children = pushTreeBranch(branch, id, b.children)
            }
        }

    })

    return tree
}

export function findEditable(branch, elements){
    let editable = null
    
    if (typeof branch.children !== 'undefined' && branch.children.length > 0) {
        editable = findEditable(branch.children[0], elements)
    }else{
        if (typeof elements[branch.id] !== 'undefined' && elements[branch.id].edit == true) {
            editable = elements[branch.id]
        }
    }

    return editable
}

export function camelToKebab(str) {
    return str.split('').map((letter, idx) => {
        return letter.toUpperCase() === letter
            ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
            : letter;
    }).join('');
}


