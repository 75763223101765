import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faCopy, faImages, faPlusSquare, fas, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { apiUrl, imgTypes } from "../config/var";
import Tab from "./tab";
import { BlockPicker } from 'react-color';
import Gallery from "./gallery";
import InputNumber from "./inputNumber";
import RichTextarea from "./richTextarea";
import elementStyles from "../config/styleFields.json"
import { SketchPicker } from "react-color";

export default class Editor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editorErrors: {},
            colorPicker: {},
            gallery : {}
        }
    }

    closeEditor(e) {
            
        this.setState(s => {
            s.gallery = {}
            s.colorPicker = {}
            s.editorErrors = {}
            return s
        }, async () => {
            document.getElementById('editor-form').reset()
            this.props.onClose(e)
        })
        
    }

    openGallery(key, isStyle){
        isStyle = isStyle || false

        let gallery = {
            show : true,
            key : key,
            isStyle : isStyle
        }

        this.setState(s => {
            s.gallery = gallery
            return s
        })
    }

    onGalleryItemClick(url) {
        if (this.state.gallery.isStyle == true){
            let value = {
                val : url,
                suffix : ''
            }

            this.props.onUpdate(this.state.gallery.key, value, true, url)
        }else{
            this.props.onUpdate(this.state.gallery.key, url, false, url)
        }
        
    }

    showColorPicker(show, key) {

        let val = 'show'

        if (show === false) {
            val = 'hide'
        }

        this.setState(s => {
            s.colorPicker[key] = val
            return s
        })
    }

    toggleAdvanced(e, id){
        e.preventDefault()

        let target = document.getElementById(id)

        if( target.classList.contains('open')){
            target.classList.remove('open')
        }else{
            target.classList.add('open')
        }
    }

    editorStyleInputCheck(e, styleKey, suffix, value) {
        let target = e.target
        let v = target.value

        if (target.checked === false) {
            v = ''
        }

        this.setStyle(v, styleKey, suffix)
    }

    editorStyleInput(e, styleKey, suffix) {
        let target = e.target

        this.setStyle(target.value, styleKey, suffix)
    }

    onColorChange(color, styleKey) {
        this.setStyle(color.hex, styleKey, '')
    }

    setStyle(v, k, s) {
        let value = {
            val: v,
            suffix: s
        }

        this.props.onUpdate(k, value, true)
    }

    editorInput(e, key) {
        this.setState(s => {
            s.editorErrors[key] = null
            return s
        })

        let target = e.target
        this.props.onUpdate(key, target.value, false)
    }

    editorStyleField(key, defaultValue, type, options) {
        let suffix = defaultValue.suffix

        if (type == null) {
            type = ''
        }

        if (options == null) {
            options = {}
        }

        let value = (typeof this.props.active.style[key] != 'undefined') ? this.props.active.style[key].val : defaultValue.val

        let field = false

        if (type == 'color') {
            let picker = false

            if (typeof this.state.colorPicker[key] != 'undefined' && this.state.colorPicker[key] == 'show') {
                picker = (
                    <div className="color-input-wrapper">
                        <SketchPicker width={320} disableAlpha={true} color={value} onChange={color => this.onColorChange(color, key)} />
                        <button type="button" className="editor-btn btn-sm btn-delete" onClick={e => this.showColorPicker(false, key)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                )
            }

            field = (
                <div>
                    <input type="text" className="input" defaultValue={value} onFocus={e => this.showColorPicker(true, key)} />
                    {picker}
                </div>
            )
        } else if (type == 'image') {
            let img = false

            if (value.length > 0) {
                value = apiUrl + value

                img = (
                    <div className="color-input-wrapper">
                        <img className="thumbnail" src={value} />
                        <button type="button" className="editor-btn btn-sm btn-delete" onClick={e => this.props.deleteImage(key, value, true)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                )
            }

            field = (
                <div className="editor-upload">
                    {img}
                </div>
            )
        } else if (type == 'radio') {
            let radios = []

            for (let option of options) {
            
                radios.push(
                    <span className="radio-inline" key={option.val}>
                        <input type="radio" name={key} className="input-radio" value={option.val} onChange={e => this.editorStyleInput(e, key, suffix)} />
                        <span>{option.name}</span>
                    </span>
                )
            }

            field = (
                <div className="editor-row">
                    {radios}
                </div>
            )
        } else if (type == 'checkbox') {
            let checked = false

            if (value == options.value) {
                checked = true
            }

            field = (
                <input type="checkbox" className="input-checkbox" onChange={e => this.editorStyleInputCheck(e, key, suffix, options)} value={options.value} defaultChecked={checked} />
            )
        }else if(type == 'number'){
            field = (
                <InputNumber value={value} inputKey={key} suffix={suffix} onChange={this.setStyle.bind(this)} />
            )
        } else {

            field = (
                <input type="text" className="input" onInput={e => this.editorStyleInput(e, key, suffix)} onPaste={e => this.editorStyleInput(e, key, suffix)} defaultValue={value} />
            )
        }

        return field
    }

    render() {
        if (this.props.active == null) return false

        let gallery = false

        if (typeof this.state.gallery.show != 'undefined' && this.state.gallery.show == true){
            gallery = <Gallery onItemClick={this.onGalleryItemClick.bind(this)} />
        }

        let fields = this.props.active.fields

        let fieldViews = []

        fields.forEach((field, i) => {
            let fieldView = ''
            let value = (typeof this.props.active.data[field.key] != 'undefined') ? this.props.active.data[field.key] : ''

            let error = false
            if (typeof this.state.editorErrors[field.key] != 'undefined' && this.state.editorErrors[field.key] !== null) {
                error = <div className="message error">{this.state.editorErrors[field.key]}</div>
            }

            switch (field.type) {
                case 'input':
                    fieldView = (
                        <div className="form-group" key={i}>
                            <label>{field.name}</label>
                            <input type="text" className="input" placeholder={field.default} onInput={e => this.editorInput(e, field.key)} onPaste={e => this.editorInput(e, field.key)} value={value} />
                            {error}
                        </div>
                    )
                    break
                case 'textarea1':
                    fieldView = (
                        <div className="form-group" key={i}>
                            <label>{field.name}</label>
                            <textarea className="input" rows={6} placeholder={field.default} onInput={e => this.editorInput(e, field.key)} onPaste={e => this.editorInput(e, field.key)} defaultValue={value}></textarea>
                            {error}
                        </div>
                    )
                    break
                case 'textarea':
                    fieldView = (
                        <div className="form-group" key={i}>
                            <label>{field.name}</label>
                            <RichTextarea inputKey={field.key} isStyle={false} onChange={this.props.onUpdate} value={value} />
                            {error}
                        </div>
                    )
                    break
                case 'file':
                    let img = false

                    if (value.length > 0) {
                        value = apiUrl + value

                        img = <img className="thumbnail" src={value} />
                    }

                    fieldView = (
                        <div className="form-group" key={i}>
                            <div className="editor-row">
                                <label className="editor-cell">{field.name}</label>
                                <div className="editor-cell">
                                    <button type="button" className="editor-btn" onClick={e => this.openGallery(field.key, false)}>
                                        <span>Open Gallery&nbsp;&nbsp;</span>
                                        <FontAwesomeIcon icon={faImages} />
                                    </button>
                                </div>
                                
                            </div>
                            
                            <div>
                                {img}
                            </div>
                            {error}
                        </div>
                    )
                    break
                default:
                    break
            }

            fieldViews.push(fieldView)
        })

        let moveUp = false
        let moveDown = false

        let topLevel = false

        for (let x = 0; x < this.props.tree.length; x++) {
            if (this.props.tree[x].id == this.props.active.id) {
                topLevel = x
                break
            }
        }

        if (topLevel !== false) {
            moveUp = (
                <button type="button" className="editor-btn" onClick={e => this.props.branchMove(topLevel, true)}>
                    <FontAwesomeIcon icon={faAngleDoubleUp} />
                </button>
            )

            moveDown = (
                <button type="button" className="editor-btn" onClick={e => this.props.branchMove(topLevel, false)}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} />
                </button>
            )
        }

        let styleFields = []

        if (typeof elementStyles[this.props.active.type] != 'undefined') {
            for (let elementStyle of elementStyles[this.props.active.type]) {
                let options = elementStyle.options || null

                styleFields.push(
                    <div className="form-group" key={elementStyle.key}>
                        <label>{elementStyle.name}</label>
                        {this.editorStyleField(elementStyle.key, elementStyle.value, elementStyle.type, options)}
                    </div>
                )

                if (elementStyle.key == 'padding'){
                    styleFields.push(
                        <div className="form-group" key="padding-advanced">
                            <label>
                                <a href="#" onClick={e => this.toggleAdvanced(e, 'padding-advanced')}>
                                    Advanced&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </a>
                            </label>
                            <div className="editor-row close" id="padding-advanced">
                                <div className="editor-cell">
                                    <span>Left</span>
                                    {this.editorStyleField('paddingLeft', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Right</span>
                                    {this.editorStyleField('paddingRight', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Top</span>
                                    {this.editorStyleField('paddingTop', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Bottom</span>
                                    {this.editorStyleField('paddingBottom', elementStyle.value, 'number')}
                                </div>
                            </div>
                        </div>
                    )
                }

                if (elementStyle.key == 'margin') {
                    styleFields.push(
                        <div className="form-group" key="margin-advanced">
                            <label>
                                <a href="#" onClick={e => this.toggleAdvanced(e, 'margin-advanced')}>
                                    Advanced&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faAngleDown} />
                                </a>
                            </label>
                            <div className="editor-row close" id="margin-advanced">
                                <div className="editor-cell">
                                    <span>Left</span>
                                    {this.editorStyleField('marginLeft', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Right</span>
                                    {this.editorStyleField('marginRight', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Top</span>
                                    {this.editorStyleField('marginTop', elementStyle.value, 'number')}
                                </div>
                                <div className="editor-cell">
                                    <span>Bottom</span>
                                    {this.editorStyleField('marginBottom', elementStyle.value, 'number')}
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }

        return (
            <div className="panel-right" onDropCapture={e => e.nativeEvent.stopPropagation()}>
                <form id="editor-form" className="editor">
                    <h3>
                        <span>Edit {this.props.active.name}</span>
                        <div className="editor-btns">
                            {moveUp}
                            {moveDown}
                            
                            <button type="button" className="editor-btn btn-close" onClick={e => this.closeEditor(e)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <button type="button" className="editor-btn btn-delete" onClick={e => this.props.onRemove(e)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>

                    </h3>
                    <div className="element-fields">
                        {fieldViews}
                        <Tab title="Design" active={true}>
                            {styleFields}
                        </Tab>
                        
                    </div>
                </form>
                {gallery}
            </div>
        )
    }
}