import { Component } from "react";

export default class Draggable extends Component{
    onDragStart(e){
        let target = e.target
        target.classList.add('dragging')
        e.dataTransfer.setData('text/plain', JSON.stringify(this.props.element))
    }

    onDragEnd(e){
        let target = e.target
        target.classList.remove('dragging')
    }

    onDrop(e){
        // e.preventDefault()
        // e.nativeEvent.stopImmediatePropagation()
        // e.nativeEvent.stop
    }

    render(){
        let icon = false
        
        if (typeof this.props.element.eicon !== 'undefined' && this.props.element.eicon.length > 0){
            icon = (
                <i className={this.props.element.eicon}></i>
            )
        } else if (typeof this.props.element.icon !== 'undefined' && this.props.element.icon.length > 0){
            icon = (
                <img src={this.props.element.icon} alt={this.props.element.name} title={this.props.element.name}/>
            )
        }else{
            
            icon = (
                <img src="icons/html.png" alt={this.props.element.name} />
            )
        }

        
        return(
            <div className="draggable" draggable="true" onDrop={e => this.onDrop(e)} onDragStart={e => this.onDragStart(e)} onDragEnd={e => this.onDragEnd(e)}>
                <span>
                    {icon}
                </span>
                
            </div>
        )
    }
}