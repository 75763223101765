import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

export default class InputNumber extends Component{
    constructor(props){
        super(props)
        this.state = {
            value : +props.value
        }
    }

    handleInput(e){
        let val = e.target.value

        this.setState(s => {
            s.value = +val
            return s
        }, () => {
            this.props.onChange(this.state.value, this.props.inputKey, this.props.suffix)
        })
    }

    handleIncrement(plus){
        let val = this.state.value

        if(plus == true){
            val++
        }else{
            val--
        }

        this.setState(s => {
            s.value = +val
            return s
        }, () => {
            this.props.onChange(this.state.value, this.props.inputKey, this.props.suffix)
        })
    }

    render(){
        return(
            <div className="input-number-wrapper">
                <input type="number" className="input" value={this.state.value} onInput={e => this.handleInput(e)} onPaste={e => this.handleInput(e)}/>
                <div className="input-btns">
                    <button type="button" className="editor-btn" onClick={e => this.handleIncrement(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button type="button" className="editor-btn" onClick={e => this.handleIncrement(false)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                </div>
            </div>
        )   
    }
}