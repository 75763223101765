import { Component } from "react";
import { camelToKebab } from "../functions/html";
import { apiUrl, imgTypes, innerHtmlTypes } from "../config/var";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

export default class Droppable extends Component{

    onDragPass(e,pass){
        let target = e.target

        let shouldDrop = target.getAttribute('data-drop')

        if (shouldDrop == null || shouldDrop === 'false') return

        e.stopPropagation()
        
        if (pass == true) {
            target.classList.add('active')
        } else {
            target.classList.remove('active')
        }
    }

    onDragStart(e) {
        let target = e.target
        target.classList.add('dragging')
        e.dataTransfer.setData('text/plain', JSON.stringify(this.props.element))
    }

    onDragEnd(e) {
        let target = e.target
        target.classList.remove('dragging')
    }

    render(){
        
        let text = (typeof this.props.element.data.text != 'undefined') ? this.props.element.data.text : ''
        let textView = false

        if( text.length > 0 ){
            textView = (
                <div>{text}</div>
            )

            if (innerHtmlTypes.indexOf(this.props.element.type) >= 0) {
                textView = (
                    <div dangerouslySetInnerHTML={{ __html: text }} ></div>
                )
            }
        }

        let className = 'pseudo-el ' + camelToKebab( this.props.element.type ) + ' ' + this.props.element.tag + ' ' + this.props.element.classList

        if( this.props.element.edit == true){
            className += ' editable' 
        }

        if (this.props.active !== 'undefined' && this.props.active == true ){
            className += ' active'
        }

        let style = {}
        
        if( typeof this.props.element.style != 'undefined' ){
            
            for (let k in this.props.element.style){
                if( k == 'backgroundImage' ){
                    let src = encodeURI( this.props.element.style[k].val )

                    src = apiUrl + src

                    style[k] = 'url(\'' + src + '\')'
                }else if(k == 'class'){
                    className += ' ' + this.props.element.style[k].val
                }else{
                    style[k] = this.props.element.style[k].val + this.props.element.style[k].suffix
                }
                
            }
        }

        let drag = {}

        if( this.props.element.drag == true ){
            drag.draggable = true
            drag.onDragStart = (e) => {
                this.onDragStart(e)
            }
            drag.onDragEnd = (e) => {
                this.onDragEnd(e)
            }
        }

        let drop = {}
        let dropZone = false
        let shouldDrop = this.props.element.drop

        if (shouldDrop == true) {

            drop.onDragEnter =  (e) => {
                this.onDragPass(e, true) 
            } 
            drop.onDragLeave =  (e) => {
                this.onDragPass(e, false) 
            }
            drop.onDrop =  (e) => {
                this.props.onDropHandler(e, this.props.element.id) 
            }

        }

        if (shouldDrop == true) {
            
            if (typeof this.props.children == 'undefined' || this.props.children == null || this.props.children.length < 1) {
                dropZone = (
                    <div className="droppable" {...drop} data-drop={this.props.element.drop} onClick={e => this.props.droppableClick(this.props.element.id)}>
                        <FontAwesomeIcon icon={faPlusSquare} />
                    </div>
                )

                drop = {}
                shouldDrop = false
            }
        }

        let img = false


        if (imgTypes.indexOf(this.props.element.type) >= 0 && typeof this.props.element.data.src != 'undefined') {
            let src = this.props.element.data.src

            src = apiUrl + this.props.element.data.src

            let height = 'auto'

            if( typeof style.height != 'undefined' ){
                height = style.height
            }

            img = <img className="img-responsive" src={src} style={{height : height}}/>

        }

        return (
            <div {...drag} {...drop} data-drop={shouldDrop} className={className} data-id={this.props.element.id} type={this.props.element.type} style={{ ...style }} onClick={e => this.props.onClick(e, this.props.element)} onDragOver={e => e.preventDefault()} >
                {textView}
                {img}
                {this.props.children}
                {dropZone}
            </div>
        )
    }
}