import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { apiUrl } from "../config/var";

export default class Gallery extends Component{
    constructor(props){
        super(props)

        this.state = {
            images : [],
            deleteMode : false,
            messages : []
        }
    }

    displayMessages(){
        let views = []

        this.state.messages.forEach((msg, i) => {
            views.push(
                <div className={'message ' + msg.type} key={i}>
                    <span>{msg.text}</span>
                    <a href="#" className="btn" onClick={e => this.removeMessage(e, i)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </a>
                </div>
            )
        })

        return views;
    }

    removeMessage(e, i){
        e.preventDefault()
        let messages = this.state.messages
        messages.splice(i, 1)

        this.setState(s => {
            s.messages = messages
            return s
        })
    }

    componentDidMount(){
        fetch(apiUrl + 'index.php?action=gallery')
        .then(response => response.json())
        .then(json => {
            let images = []

            if( json.status == 'success' ){
                images = json.data
            }

            this.setState(s => {
                s.images = images
                return s
            })
        })

    }

    itemClick(e, url){
        // e.preventDefault()
        console.log(url)
        this.props.onItemClick(url)
    }

    toggleDeleteMode(e){
        let deleteMode = false

        if( e.target.checked == true ){
            deleteMode = true
        }

        this.setState(s => {
            s.deleteMode = deleteMode
            return s
        })
    }

    deleteImages(){
        let images = []

        document.querySelectorAll('.select-delete:checked').forEach(select => {
            images.push(select.value)
        })

        if( images.length == 0 ) return

        if(!window.confirm(`Delete ${images.length} images?`)) return

        let postData = new FormData()

        for(let img of images){
            postData.append('images[]', img)
        }
        
        fetch(apiUrl + 'index.php?action=filedelete', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            referrerPolicy: 'no-referrer',
            body: postData
        })
            .then(response => response.json())
            .then(json => {
                let messages = this.state.messages
                messages.push({
                    type : json.status,
                    text : json.message
                })

                let current = this.state.images

                for(let img of images){
                    current.splice(current.indexOf(img), 1)
                }

                this.setState(s => {
                    s.messages = messages
                    s.images = current
                    return s
                })
            })
    }

    uploadFiles(e){
        
        let target = e.target

        if (typeof target.files == 'undefined' || target.files.length < 1 || target.value.length < 1) {
            return
        }

        let images = []
        let errors = []

        const allowedExts = ['.png', '.jpg', '.jpeg', '.gif']

        for(let file of target.files){
            const ext = file.name.substr(file.name.lastIndexOf('.'), file.name.length)

            if (allowedExts.indexOf(ext.toLowerCase()) < 0) {
                errors.push({
                    type: 'error',
                    text: 'Unsupported file type'
                })
                
            }else{
                images.push(file)
            }
        }

        let messages = this.state.messages
        messages.push(...errors)

        this.setState(s => {
            s.messages = messages
            return s
        })

        if(images.length == 0) return

        let postData = new FormData()

        for(let img of images){
            postData.append('files[]', img)
        }

        fetch(apiUrl + 'index.php?action=fileupload', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            referrerPolicy: 'no-referrer',
            body: postData
        })
            .then(response => response.json())
            .then(json => {
                let messages = this.state.messages
                let images = this.state.images

                if(json.status == 'error'){
                    messages.push({
                        type : 'error',
                        text : json.message
                    })
                }else{
                    if (json.errors){
                        for(let error of json.errors){
                            messages.push({
                                type: 'error',
                                text: error
                            })
                        }
                    }

                    messages.push({
                        type: 'success',
                        text: json.message
                    })

                    if(json.data.length > 0){
                        images.unshift(...json.data)
                    }
                }

                this.setState(s => {
                    s.messages = messages
                    s.images = images
                    return s
                })
            })
    }

    render(){
        let imageViews = []

        for(let i in this.state.images ){
            let img = this.state.images[i]
            let imgName = img.substring(img.lastIndexOf('/') + 1, img.length)
            let deleteBox = false

            if( this.state.deleteMode == true ){
                deleteBox = <input type="checkbox" name="delete[]" className="select-delete" value={img} />
            }

            imageViews.push(
                <span className="gallery-item" onClick={e => this.itemClick(e, img)} key={i}>
                    {deleteBox}
                    <span>
                        <img className="thumbnail" src={apiUrl + img} alt={imgName}/>
                    </span>
                    <span>{imgName}</span>
                </span>
            )
        }

        return(
            <div className="gallery-wrapper">
                <div className="gallery-action-wrapper">
                    <div className="gallery-action">
                        <span>Delete Mode</span>
                        <input type="checkbox" value="delete" onChange={e => this.toggleDeleteMode(e)} />&nbsp;&nbsp;
                        <button type="button" className="btn editor-btn btn-delete" onClick={e => this.deleteImages(e)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </div>
                    
                    <div className="gallery-action">
                        <span>Upload Images</span>
                        <input type="file" multiple={true} onChange={e => this.uploadFiles(e)} />
                    </div>
                </div>
                <div className="messages-wrapper">
                    {this.displayMessages()}
                </div>
                <div className="gallery-items-wrapper">
                    {imageViews}
                </div>
                
            </div>
        )
    }
}