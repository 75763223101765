import { Component } from "react";
import Droppable from "./droppable";
import elements from "../config/elements.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTimes } from "@fortawesome/free-solid-svg-icons";
import { apiUrl } from "../config/var";

export default class LeftPanel extends Component{
    counter = 0

    constructor(props){
        super(props)
        this.counter = 0
        this.state = {
            page : {
                elements : {},
                tree : [],
                files : []
            },
            active : null,
            editorErrors : {},
            colorPicker : {},
            floatingRight : false
        }
    }

    showFloatingRight(e, id){
        e.stopPropagation()

        this.setState(s => {
            s.floatingRight = id
            return s
        })
    }

    hideFloatingRight(e) {
        
        if(typeof e != 'undefined' && e !== null ){
            e.preventDefault()
        }

        this.setState(s => {
            s.floatingRight = false
            return s
        })
    }

    floatingRightItemClick(e, element){
        e.preventDefault()

        if( this.state.floatingRight !== false ){
            let id = this.state.floatingRight

            this.addElement(element, id)
        }

        this.hideFloatingRight()
    }

    floatingRightView(){
        if (this.state.floatingRight === false) return ''

        let lines = []

        for(let key in elements){
            lines.push(
                <li key={key}>
                    <a href="#" onClick={e => this.floatingRightItemClick(e, elements[key])}>{elements[key].name}</a>
                </li>
            )
        }

        return(
            <div className="floating-right">
                <ul>
                    {lines}    
                </ul>    
                <button type="button" className="editor-btn btn-delete" onClick={e => this.hideFloatingRight(e)}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        )
    }

    pageView(){
        let html = []
        let _inst = this
// console.log(this.props.page.elements)
        this.props.page.tree.forEach( branch => {
            let id = branch.id

            let childernHtml = []

            if( branch.children.length > 0 ){
                childernHtml = this.childrenView(branch.children)
            }

            let active = false

            if (this.props.active !== null && this.props.active.id == id){
                active = true
            }

            html.push(
                <Droppable key={id} active={active} apiUrl={apiUrl} element={this.props.page.elements[id]} onClick={this.props.onElementClick} onDropHandler={this.props.onDrop} droppableClick={this.props.onFixedDropClick}>
                    {childernHtml}
                </Droppable>
            )
            
        })

        return html
    }

    childrenView( children ){
        
        let html = []
        let _inst = this

        children.forEach( child => {
            let id = child.id

            let childernHtml = []

            if( child.children.length > 0 ){
                childernHtml = _inst.childrenView(child.children)
            }

            let active = false

            if (this.props.active !== null && this.props.active.id == id) {
                active = true
            }

            html.push(
                <Droppable key={id} active={active} apiUrl={apiUrl} element={this.props.page.elements[id]} onClick={this.props.onElementClick} onDropHandler={this.props.onDrop} droppableClick={this.props.onFixedDropClick}>
                    {childernHtml}
                </Droppable>
            )
        })

        return html;
    }

    render(){
        
        return(
            <div className="preview droppable" id="preview" onDragOver={e => e.preventDefault()} onDrop={e => this.props.onDrop(e, null)} data-drop="true">
                {this.pageView()}
                <div className="fixed-drop" onDragOver={e => e.preventDefault()} onDrop={e => this.props.onDrop(e, null)} data-drop="true" onClick={e => this.props.onFixedDropClick(null)}>
                    <FontAwesomeIcon icon={faPlusSquare} />
                </div>
            </div>
        )
    }
}