import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";

export default class Tab extends Component{
    constructor(props){
        super(props)
        this.state = {
            active : this.props.active || false
        }
    }

    toggle(e){
        e.preventDefault()

        let active = true

        if (this.state.active == true) {
            active = false
        }

        this.setState( s => {
            
            s.active = active
            
            return s
        })
    }

    render(){
        let display = 'none'
        let icon = faPlus
        
        if( this.state.active == true ){
            display = 'block'
            icon = faMinus
        }

        return(
            <div className="tab">
                <div className="tab-handle">
                    <a href="#" onClick={e => this.toggle(e)}>
                        <span>{this.props.title}</span>
                        <FontAwesomeIcon icon={icon} />
                    </a>
                </div>
                <div className="tab-body" style={{display : display}}>
                    {this.props.children}    
                </div>   
            </div>
        )
    }
}